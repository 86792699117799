// Initialize Zurb Foundation. This needs to be at the top.
$(document).foundation();

const win = window;
const doc = document;

// Set Keycodes for accessibilty use
const KEYCODE = {
  ESC: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
};

//--------------------------------------------------

const GlobalUtils = {
  // cache DOM elements
  winWidth: win.innerWidth,
  maskElms: doc.body.querySelectorAll('.js-initial-load'),
  _revealElms: function () {
    let arr = this.maskElms;
    for (let i = arr.length - 1; i >= 0; i--) {
      arr[i].classList.remove('js-initial-load');
    }
  },
  _reEqualize: function () {
    // reflow equalized elements after content is fully rendered.
    // In a future version, FlexBox would eliminate this need.
    let equiPromoThree = $('.promo--3-up[data-equalizer="true"]');
    if (equiPromoThree.length) {
      let reflowPromoThree = new Foundation.Equalizer(equiPromoThree);
      reflowPromoThree.applyHeight();
    }
    let equiPromoFour = $('.promo--4-up[data-equalizer="true"]');
    if (equiPromoFour.length) {
      let reflowPromoFour = new Foundation.Equalizer(equiPromoFour);
      reflowPromoFour.applyHeight();
    }
    let equiMegaFooter = $('.footer__mega').find('.row[data-equalizer="true"]');
    if (equiMegaFooter.length) {
      let reflowMegaFooter = new Foundation.Equalizer(equiMegaFooter);
      reflowMegaFooter.applyHeight();
    }
  },
  _addRemoveClasses: function (arr, action, name) {
    arr.forEach(function (e) {
      for (let i = e.length - 1; i >= 0; i--) {
        action === 'remove'
          ? e[i].classList.remove(name) : e[i].classList.add(name);
      }
    });
  },
  bindEvents: function () {
    win.onload = () => this._reEqualize();
  },
  init: function () {
    this.bindEvents();
  }
};
GlobalUtils.init();

//--------------------------------------------------

// sticky header on scroll up http://wicky.nillia.ms/headroom.js/
const StickyMasthead = new Headroom(document.querySelector('.template__sticky-header'), {
  offset: 12,
  tolerance: {
    up: 5,
    down: 5
  },
  classes: {
    initial: 'headroom', // when element is initialised
    pinned: 'headroom--pinned', // when scrolling up
    unpinned: 'headroom--unpinned', // when scrolling down
    top: 'headroom--top', // when above offset
    notTop: 'headroom--not-top', // when below offset
    bottom: 'headroom--bottom', // when at bottom of scoll area
    notBottom: 'headroom--not-bottom' // when not at bottom of scroll area
  }
});
StickyMasthead.init();

//--------------------------------------------------

const ContextualNav = {
  // cache DOM elements
  $contextualNavSidebar: $('.contextual-nav__sidebar'),
  $contextualNav: $('.contextual-nav > div'),
  $contextualNavDrawer: $('.contextual-nav__drawer'),
  $contextualNavDrawerToggle: $('.contextual-nav__drawer__link'),
  _mobileDrawer: function (e) {
    let $drawer = $('.contextual-nav__drawer nav');
    this.$contextualNavDrawerToggle.toggleClass('active');
    if (this.$contextualNavDrawerToggle.hasClass('active')) {
      $drawer.slideDown();
    } else {
      $drawer.slideUp(300);
    }
    if (this.$contextualNavDrawer.is(':visible')) {
      e.preventDefault();
    }
  },
  _toggleMobile: function () {
    // contextual right nav functionality for desktop and mobile
    if (this.$contextualNav.length && this.$contextualNavDrawer.is(':visible')) {
      this.$contextualNav.appendTo(this.$contextualNavDrawer);
    } else {
      this.$contextualNav.appendTo(this.$contextualNavSidebar);
      this.$contextualNavDrawerToggle.removeClass('active');
      $('.contextual-nav__sidebar nav').removeAttr('style');
    }
  },
  bindEvents: function () {
    this.$contextualNavDrawerToggle.on('click', (e) => this._mobileDrawer(e));
  },
  init: function () {
    this.bindEvents();
  }
};
if (ContextualNav.$contextualNav.length) { // check for a contextual nav menu
  ContextualNav.init();
}

//--------------------------------------------------

const Masthead = {
  /*
  max height for navbar. if the navbar's calculated height is larger
  than this number, the condition for a wrapping navbar returns true
  */
  navBarTarget: 50,

  // establish a minimum breakpoint in case the navbar only has a couple of links
  minNavBarBreakpoint: 700,

  // cache DOM elements
  $btnHamburger: $('#btn-hamburger'),
  $offCanvasMenuClose: $('.js-off-canvas-overlay'), // offcanvas menu content overlay
  $offCanvasMenu: $('#offCanvas'),
  $offCanvasContent: $('.off-canvas-content'),
  $utilities: $('.template__utilities'),
  $search: $('#search'),
  $searchField: $('input[name="q"]'),
  $siteTitleWrapper: $('#site-title-wrapper'),
  $siteTitle: $('#site-title'),
  $navBar: $('#desktop-nav'),
  $navBarList: $('#desktop-nav ul:visible'),
  $navBarMenuWrapper: $('#main-menu-wrapper'),
  $mainMenu: $('#main-menu'),
  checkMainNavWrap: function () {
    let checkAgain;
    clearTimeout(checkAgain);
    if (this.$navBarList) {
      this._toggleMobile();
    } else {
      checkAgain = setTimeout(this.checkMainNavWrap, 50);
    }
  },
  _toggleMobile: function () { // toggle hiding/showing desktop nav and search vs mobile
    let navBarHeight = this.$navBar.outerHeight();
    if (navBarHeight > this.navBarTarget || GlobalUtils.winWidth <= this.minNavBarBreakpoint) {
      // main navbar wrapping
      $([
        // this.$search[0],
        // this.$btnHamburger[0],
        this.$navBar[0],
        this.$siteTitleWrapper[0]
      ]).addClass('mobile');
    } else {
      // main navbar not wrapping
      $([
        // this.$search[0],
        // this.$btnHamburger[0],
        this.$navBar[0],
        this.$siteTitleWrapper[0]
      ]).removeClass('mobile');
      if (this.$offCanvasMenu.hasClass('is-open')) {
        // resset open offcanvas if triggering mobile view
        this.$btnHamburger.click();
      }
    }
    if (ContextualNav.$contextualNav) { // check for a contextual nav menu
      ContextualNav._toggleMobile();
    }
  },
  _resetMasthead: function () { // put things back
    this.$siteTitle.appendTo(this.$siteTitleWrapper); // move site title back
    this.$mainMenu.appendTo(this.$navBarMenuWrapper); // move menu back to desktop nav bar
    this.$search.appendTo(this.$utilities); // move search back
    $([
      this.$search[0],
      this.$btnHamburger[0],
      this.$navBar[0],
      this.$siteTitleWrapper[0]
    ]).addClass('mobile');
    StickyMasthead.init(); // re-enable sticky header when masthead is closed
    this.$offCanvasMenu.attr('aria-hidden', 'true');
    this.$offCanvasContent.attr('aria-hidden', 'false');
  },
  _toggleHamburgerIcon: function () {
    $('html,body').scrollTop(0);// fixes quirkiness with transition from sticky header to off canvas
    this.$btnHamburger.toggleClass('is-active');
    // dynamically move the unordered list from the main nav to the off canvas
    if (this.$btnHamburger.hasClass('is-active')) { // open event
      StickyMasthead.destroy(); // disable sticky header when offcanvas is open
      this.$offCanvasMenu.attr('aria-hidden', 'false');
      this.$offCanvasContent.attr('aria-hidden', 'true');
      // move search, site title, and menu into offcanvas menu
      $([
        this.$search[0],
        this.$siteTitle[0],
        this.$mainMenu[0]
      ]).appendTo(this.$offCanvasMenu);
      setTimeout(() => {
        this.$searchField.focus();
      }, 200);
    } else { // close event
      this._resetMasthead();
    }
  },
  _keyboardCtrl: function (e) {
    let code = (e.keyCode ? e.keyCode : e.which);
    if (code === 9 && this.$btnHamburger.hasClass('is-active')) {
      $('.off-canvas-content').focusin(() => {
        if (this.$btnHamburger.hasClass('is-active')) {
          this.$offCanvasMenuClose.click();
        }
      });
    } else if (code === 27 && this.$btnHamburger.hasClass('is-active')) {
      if (this.$btnHamburger.hasClass('is-active')) {
        this.$offCanvasMenuClose.click();
      }
    }
  },
  _checkWinWidth: function () {
    if (win.innerWidth !== GlobalUtils.winWidth) {
      GlobalUtils.winWidth = win.innerWidth; // reassign current width
      this._toggleMobile();
    }
  },
  bindEvents: function () {
    // toggle hamburger icon state
    this.$btnHamburger.click(() => this._toggleHamburgerIcon());

    // if hamburger menu is open and content area is clicked, close menu
    this.$offCanvasMenuClose.on('click', () => this._toggleHamburgerIcon());

    // menu keyboard controls
    win.addEventListener('keyup', (e) => this._keyboardCtrl(e));

    // checking mobile vs desktop on resize
    win.addEventListener('resize', () => this._checkWinWidth());
  },
  init: function () {
    this.checkMainNavWrap();
    this.bindEvents();
  }
};
Masthead.init();

//--------------------------------------------------

const Directory = {
  // cache DOM elements
  $directoryIndex: $('#directory-index'),
  $directoryFilters: $('.directory form'),
  $resetFilterCategories: $('#reset-filter-categories'),
  $dropDowns: $('.filter-category'),
  $columnBlock: $('.column-block'),
  firstDirectoryCategory: '',
  addDataAttr: function () {
    this.$columnBlock.each(function () {
      // format as LastnameMiddlenameFirstname
      let firstName = $(this).find('.first-name').text().replace(/[\W_]+/g, '');
      let middleName = $(this).find('.middle-name').text().replace(/[\W_]+/g, '');
      let lastNameSuf = $(this).find('.last-name').text().trim().split(',')[0];
      let lastName = lastNameSuf.replace(/[\W_]+/g, '');
      let fullName = lastName + middleName + firstName;
      $(this).attr('data-name', fullName);
    });
  },
  _getAllUrlParams: function (url) {

    // get query string from url (optional) or window
    let queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    let obj = {};

    // if query string exists
    if (queryString) {

      // stuff after # is not part of query string, so get rid of it
      queryString = queryString.split('#')[0];

      // split our query string into its component parts
      let arr = queryString.split('&');

      for (let i = 0; i < arr.length; i++) {
        // separate the keys and the values
        let a = arr[i].split('=');

        // in case params look like: list[]=thing1&list[]=thing2
        let paramNum = undefined;
        let paramName = a[0].replace(/\[\d*\]/, function (v) {
          paramNum = v.slice(1, -1);
          return '';
        });

        // set parameter value (use 'true' if empty)
        let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        // (optional) keep case consistent
        paramName = paramName.toLowerCase();
        paramValue = paramValue.toLowerCase();

        // if parameter name already exists
        if (obj[paramName]) {
          // convert value to array (if still string)
          if (typeof obj[paramName] === 'string') {
            obj[paramName] = [obj[paramName]];
          }
          // if no array index number specified...
          if (typeof paramNum === 'undefined') {
            // put the value on the end of the array
            obj[paramName].push(paramValue);
          }
          // if array index number specified...
          else {
            // put the value at that index number
            obj[paramName][paramNum] = paramValue;
          }
        }
        // if param name doesn't exist yet, set it
        else {
          obj[paramName] = paramValue;
        }
      }
    }
    return obj;
  },
  preSort: function () {
    // look for URL params and sort accordingly
    this.firstDirectoryCategory = this._getAllUrlParams().cat1;
    if (location.search && this.firstDirectoryCategory) {
      this._filterItems(this.firstDirectoryCategory);
    } else {
      this.$directoryIndex.find('.column-block')
        .sort(this._sortItems).prependTo(this.$directoryIndex);
      this.$directoryIndex.show();
    }
    this.$dropDowns.each(function () {
      let elm = Directory;
      if ($(this).val() || $('#directory-search-box').val()) {
        elm.$resetFilterCategories.show();
        Directory._filterItems(elm.firstDirectoryCategory);
        Directory._checkFields();
        return false;
      }
    });
  },
  _changeFilters: function () {
    this.$resetFilterCategories.show();
    this._filterItems(this.firstDirectoryCategory);
    this._checkFields();
  },
  _clearFilters: function () {
    // reset form
    this.$directoryFilters.not(':hidden')[0].reset();

    // re-sort items
    let clearItems = setTimeout(function () {
      let elm = Directory;
      Directory._filterItems(elm.firstDirectoryCategory);
      elm.$resetFilterCategories.hide();
      clearTimeout(clearItems);
    }, 100);
  },
  _checkFields: function () {
    this.$dropDowns.each(function (index) {
      // if all, plus the the name search is empty
      if (!$(this).val() && $(this).length === index && !$('#directory-search-box').val()) {
        // hide clear filter button
        // $resetFilterCategories.hide();
      } else if ($(this).val()) {
        // escape loop if dropdown has value
        return false;
      }
    });
  },
  _filterItems: function (preFilter) {
    this.$columnBlock.removeClass('filtered').hide().filter(function () {
      let elm = Directory;
      // build regular expresion from name search field
      let regExName = new RegExp($('#directory-search-box').val().trim(), 'ig');

      // cache some things
      let rtnData = $(this).find('li').first().text().match(regExName);
      let component = $(this);

      if (elm.$dropDowns.length > 0) {
        let regEx = [];

        // loop through drop downs, get values, and generate regular expression(s) from them
        elm.$dropDowns.each(function (i) {
          if (i === 0 && preFilter) {
            regEx[i] = new RegExp(preFilter, 'ig');
          } else {
            regEx[i] = new RegExp($('#filter-category-' + (i + 1))
              .val().trim().replace(/[^A-Z0-9]+/ig, '-').toLowerCase(), 'ig');
          }
        });

        // loop through each regular expression and match the directory categories for the return data
        regEx.forEach(function (currentRegEx) {
          rtnData = rtnData && component.find('span').text().replace(/[^A-Z0-9]+/ig, '-').toLowerCase().match(currentRegEx);
        });
      }
      // return the filtered data
      return rtnData;
    }).addClass('filtered');

    // add or remove "no results" message as applicable
    if (!this.$columnBlock.hasClass('filtered')) {
      if ($('#no-results').length === 0) {
        this.$directoryFilters
          .append('<div id="no-results">No results found</div>');
      }
    } else {
      $('.filtered').show();
      $('#no-results').remove();
    }
    // call sorting function for filtered items
    this.$directoryIndex
      .find('.filtered')
      .sort(this._sortItems)
      .prependTo(this.$directoryIndex);
    this.$directoryIndex.show();
  },
  _sortItems: function (a, b) {
    // sort the filtered data
    return ($(b).data('name')) < ($(a).data('name')) ? 1 : -1;
  },
  bindEvents: function () {
    $(window).load(() => this.preSort());

    this.$directoryFilters.submit((e) => e.preventDefault());

    this.$resetFilterCategories.on('click', () => this._clearFilters());

    this.$directoryFilters.on('change keyup', () => this._changeFilters());
  },
  init: function () {
    this.addDataAttr();
    this.bindEvents();
  }
};
if (Directory.$directoryIndex.length) { // check for a directory
  Directory.init();
}

//--------------------------------------------------

$(document).ready(function () {
  let waitForRender = setTimeout(() => {
    $.when(Masthead.checkMainNavWrap()).done(() => {
      GlobalUtils._revealElms(); // unhide elemnts after elements have been calculated and if needed, relocated
      clearTimeout(waitForRender);
    });
  }, 100);

  // initialize jquery-responsive-tables plugin
  $.responsiveTables();

  // initialize foundaiton-datepicker
  $(function () {
    $('.dp').fdatepicker({
      format: 'mm-dd-yyyy',
      disableDblClickSelection: true,
      leftArrow: '<<',
      rightArrow: '>>',
      closeIcon: 'X',
      closeButton: false
    });
  });
});

//--------------------------------------------------
// Search Suggestion code
function searchSuggest() {
  var xhttp = new XMLHttpRequest();
  var userQuery = document.getElementById("q").value;
  if (userQuery.length > 1) {
      xhttp.onreadystatechange = function(){
          if(this.readyState== 4 && this.status == 200) {
              document.getElementById("searchSuggestion").innerHTML = this.responseText;
          }
      };
      xhttp.open("GET", "https://" + window.location.hostname + "/framework/search/search-suggestion?userQuery="+userQuery, true);
      xhttp.send();


      var val = document.getElementById("q").value;
      var val_mobile = document.getElementById("q_mobile").value;
      var opts = document.getElementById("suggested-terms").childNodes;
      var dataSuggestion = document.getElementById("searchbox-type");
      for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
          // An item was selected from the list
          dataSuggestion.value = opts[i].value;
          // alert(dataSuggestion.value);
          break;
        }
      }

      for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val_mobile) {
          // An item was selected from the list
          dataSuggestion.value = opts[i].value;
          // alert(dataSuggestion.value);
          break;
        }
      }
  }
}


//-------------------------------------------------
// Fade in containers on scroll

const content = document.querySelectorAll('.js-fade-in');
const inview = function(entries) {
  entries.forEach(entry => {
    if (entry.isIntersecting){
      entry.target.classList.add('js-inview');
      observer.unobserve(entry.target);
      if (entry.target === content[content.length - 1]) {
        observer.disconnect();
      }
    }
  });
}

const observer = new IntersectionObserver(inview);
for(let i=0; i < content.length; i++) {
  observer.observe(content[i]);
}


//--------------------------------------------------
// Dialog open and close

// Open
const dialog = document.querySelector('.dialog');
const dialogMask = dialog.querySelector('.dialog__mask');
const dialogWindow = dialog.querySelector('.dialog__window');
const dialogClose = dialog.querySelector('.dialog__close');
const dialogContent = dialog.querySelector('.dialog__content');
const dialogPrevNext = dialog.querySelector('.dialog__prevNext')
const galleryPrev = dialogPrevNext.querySelector('.gallery__prev');
const galleryNext = dialogPrevNext.querySelector('.gallery__next');

let previousActiveElement;

const openDialog = () => {
  // Grab a reference to the previous activeElement
  // we'll want to restore this when we close the dialog
  previousActiveElement = document.activeElement;

  // Add class opened to dialog elements
  // this will make it visible
  dialog.classList.add('opened');

  // Listen for things that should close the dialog
  dialogClose.addEventListener('click', closeDialog);
  dialogMask.addEventListener('click', closeDialog);
  document.addEventListener('keydown', checkCloseDialog);

  // Remove inert property from header, main, and footer.
  document.querySelector('header').inert = true;
  document.querySelector('main').inert = true;
  document.querySelector('footer').inert = true;

  // Since not every page has a banner or mega footer we need to check for them
  if ( document.querySelector('.template__photo') == null) {
  } else {
    document.querySelector('.template__photo').inert = true;
  }

  if ( document.querySelector('.footer__mega') == null ) {

  } else {
    document.querySelector('.footer__mega').inert = true;
  }

  // Clear any previous styles to previous and next buttons
  galleryNext.style.display = 'inline-block';
  galleryNext.setAttribute('aria-hidden', 'false');
  galleryPrev.style.display = 'inline-block';
  galleryPrev.setAttribute('aria-hidden', 'false');

  // Prevent Body scroll
  const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
  const body = document.body;
  body.style.position = 'fixed';
  body.style.top = `-${scrollY}`;

  // Find first focusable element (the close button)
  dialog.querySelector('button').focus();
};

function checkCloseDialog(e) {
  if (e.keyCode === KEYCODE.ESC) {
    closeDialog();
  }
};

//Close

const closeDialog = () => {
  // Clean up event listeners
  dialogClose.removeEventListener('click', closeDialog);
  dialogMask.removeEventListener('click', closeDialog);
  document.removeEventListener('keydown', checkCloseDialog);

  // Remove inert property from header, main, and footer.
  document.querySelector('header').inert = false;
  document.querySelector('main').inert = false;
  document.querySelector('footer').inert = false;
  // Since not every page has a banner or mega footer we need to check for them
  if ( document.querySelector('.template__photo') == null) {
  } else {
    document.querySelector('.template__photo').inert = false;
  }

  if ( document.querySelector('.footer__mega') == null ) {

  } else {
    document.querySelector('.footer__mega').inert = false;
  }

  // If the image gallery was open we need to set the mobile nav to inert false
  if (document.getElementById('mobile-nav').inert == true) {
    document.getElementById('mobile-nav').inert = false;
  }

  // Clear any previous styles to previous and next buttons
  galleryNext.style.display = 'none';
  galleryNext.setAttribute('aria-hidden', 'true');
  galleryPrev.style.display = 'none';
  galleryPrev.setAttribute('aria-hidden', 'true');


  // Add scroll back to body
  const body = document.body;
  const scrollY = body.style.top;
  body.style.position = '';
  body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);

  // remove class open
  dialog.classList.remove('opened');

  // Quick and dirty way to remove all active classes from menu buttons
  document.getElementById('mobile-nav').querySelectorAll('button').forEach(button => {
    button.classList.remove('active');
  });

  if ( dialog.classList.contains('dialog__mobile-nav') ) {
    dialog.classList.remove('dialog__mobile-nav');
  }

  // Set dialog content innerHTML to nothing and remove classes
  dialogContent.innerHTML = "";

  var mnMagnifying = document.getElementById('js-mobileNavMag');
  var mnHamburger = document.getElementById('js-mobileNavHamburger');
  var mnShareIcon = document.getElementById('js-mobileNavShare');
  if ( mnMagnifying.classList.contains('change') ) {
    mnMagnifying.classList.remove('change');
  }
  if ( mnHamburger.classList.contains('change') ) {
    mnHamburger.classList.remove('change');
  }
  if ( mnShareIcon.classList.contains('change') ) {
    mnShareIcon.classList.remove('change');
  }

  // Clear figure and prev next is present
  if (dialogFigure.classList.contains('gallery')) {
    dialogFigure.classList.remove('gallery');
  }

  if (dialogPrevNext.classList.contains('gallery')) {
    dialogPrevNext.classList.remove('gallery');
  }

  document.querySelectorAll(".image-gallery").forEach(gallery => {
    gallery.querySelectorAll('.image-gallery__button').forEach(btn => {
      if (btn.classList.contains("current")) {
        btn.classList.remove("current");
      }
    })
  })

  // Restore previous active element if available
  if ( previousActiveElement ) {
    previousActiveElement.focus();
  }

};

window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
})
//---------------------------------------------------

// Image Gallery

document.querySelectorAll(".image-gallery").forEach(gallery => {
  gallery.querySelectorAll('.image-gallery__button').forEach((btn, index, array) => {

    // Pull the img source, and caption all images
    var imageSrc = btn.dataset.srcimg;
    var imageCaption = btn.dataset.caption;

    // Set a click event for when the images are clicked
    btn.addEventListener('click', () => {

      // Then add the HTML to the dialog Window and run openDialog function located in app.js
      dialogContent.innerHTML = "<figure class='gallery'>" +
      "<img src='" + imageSrc + "'>" +
      "<figcaption>" + imageCaption + "</figcaption>" +
      "</figure>";

      // Add event listeners to the prev and next buttons
      galleryPrev.addEventListener('click', insertPrev);
      galleryNext.addEventListener('click', insertNext);
      document.addEventListener('keydown', checkPrevNext);

      btn.classList.add('current');
      dialogPrevNext.classList.add('gallery');
      dialog.classList.add('dialog__gallery');
      document.getElementById('mobile-nav').inert = true;

      openDialog();

      if (btn.dataset.index == 1) {
        galleryPrev.style.display = 'none';
      } else if (btn.dataset.index == array.length) {
        galleryNext.style.display = 'none';
      }
    });
  });
})

function insertPrev() {
  var currentImage = document.querySelector("li.image-gallery__button.current");
  var previousImage = currentImage.previousElementSibling;
  var dialogFigure = dialogContent.querySelector('figure');

  dialogFigure.querySelector('img').src = previousImage.dataset.srcimg;
  dialogFigure.querySelector('figcaption').innerHTML = previousImage.dataset.caption;

  currentImage.classList.remove('current');
  previousImage.classList.add('current');

  var currentImage = previousImage;

  if (currentImage.previousElementSibling == null) {
    galleryPrev.style.display = 'none';
  }

  if (currentImage.nextElementSibling != null) {
    galleryNext.style.display = 'inline-block';
  }
}

function insertNext() {
  var currentImage = document.querySelector("li.image-gallery__button.current");
  var nextImage = currentImage.nextElementSibling;
  var dialogFigure = dialogContent.querySelector('figure');

  dialogFigure.querySelector('img').src = nextImage.dataset.srcimg;
  dialogFigure.querySelector('figcaption').innerHTML = nextImage.dataset.caption;

  currentImage.classList.remove('current');
  nextImage.classList.add('current');

  var currentImage = nextImage;

  if (currentImage.nextElementSibling == null) {
    galleryNext.style.display = 'none';
  }

  if ( currentImage.previousElementSibling != null ) {
    galleryPrev.style.display = 'inline-block';
  }
}

function checkPrevNext(e) {
  if (e.keyCode === KEYCODE.LEFT) {
    insertPrev();
  } else if (e.keyCode === KEYCODE.RIGHT) {
    insertNext();
  }
};

// Mobile Menu
// Pull in search and menu variables - Variables are prefixed with 'm' and 'n' for mobile nav
var mnSearchBtn = document.getElementById('js-searchButton');
var mnMenuBtn = document.getElementById('js-menuButton');
var mnShareBtn = document.getElementById('js-shareButton');
var mnMenu = document.getElementById('js-mobile-menu');
var mnSearch = document.getElementById('js-mobile-searchBar');
var mnShare = document.getElementById('js-mobile-share');
var mnMagnifying = document.getElementById('js-mobileNavMag');
var mnHamburger = document.getElementById('js-mobileNavHamburger');
var mnShareIcon = document.getElementById('js-mobileNavShare');
var mnSearchBox = document.getElementById('mobileSearchBox');
var mnMenuText = mnMenuBtn.lastChild.data;
var mnSearchText = mnSearchBtn.lastChild.data;
var mnShareText = mnShareBtn.lastChild.data;


const toggleSearch = () => {

  if ( mnSearchBtn.classList.contains('active') ) {
    mnSearchBtn.classList.remove('active');
    mnMagnifying.classList.remove('change');
    mnSearchBtn.lastChild.data = mnSearchText;

    if ( dialog.classList.contains('opened') ) {
      closeDialog();
      mnSearchBtn.lastChild.data = mnSearchText;
    }

  } else {
    // check to see if other buttons are open, and close them
    if ( mnMenuBtn.classList.contains('active') ) {
      mnMenuBtn.classList.remove('active');
      mnHamburger.classList.remove('change');
      mnMenuBtn.lastChild.data = mnMenuText;
    } else if ( mnShareBtn.classList.contains('active') ){
      mnShareBtn.classList.remove('active');
      mnShareIcon.classList.remove('change');
      mnShareBtn.lastChild.data = mnShareText;
    }

    // add class active to search and replace innerHTML
    mnSearchBtn.classList.add('active');
    mnMagnifying.classList.add('change');
    dialogContent.innerHTML = mnSearch.innerHTML;
    mnSearchBox.focus();
    setFilterFormHeight();
    loadCurrentFilter();
    mnSearchBtn.lastChild.data = mnSearchBtn.dataset.canrLanguageClose;


    // Do nothing if dialog is already open otherwise open dialog
    if (dialog.classList.contains('opened')) {} else {
      dialog.classList.add('dialog__mobile-nav');
      openDialog();
      setFilterFormHeight();
    }
  }
}

const toggleMenu = () => {
  if ( mnMenuBtn.classList.contains('active') ) {
    mnMenuBtn.classList.remove('active');
    mnHamburger.classList.remove('change');
    mnMenuBtn.lastChild.data = mnMenuText;

    if ( dialog.classList.contains('opened') ) {
      closeDialog();
      mnMenuBtn.lastChild.data = mnMenuText;
    }

  } else {

    if ( mnSearchBtn.classList.contains('active') ) {
      mnSearchBtn.classList.remove('active');
      mnMagnifying.classList.remove('change');
      mnSearchBtn.lastChild.data = mnSearchText;
    } else if ( mnShareBtn.classList.contains('active') ){
      mnShareBtn.classList.remove('active');
      mnShareIcon.classList.remove('change');
      mnShareBtn.lastChild.data = mnShareText;
    }

    // add class active to menu and replace innerHTML
    mnMenuBtn.classList.add('active');
    mnHamburger.classList.add('change');
    dialogContent.innerHTML = mnMenu.innerHTML;
    mnMenuBtn.lastChild.data = mnMenuBtn.dataset.canrLanguageClose;

    // Do nothing if dialog is already open otherwise open dialog
    if (dialog.classList.contains('opened')) {} else {
      dialog.classList.add('dialog__mobile-nav');
      dialogContent.innerHTML = mnMenu.innerHTML;
      openDialog();
    }
  }
}

const toggleShare = () => {

  if ( mnShareBtn.classList.contains('active') ) {
    mnShareBtn.classList.remove('active');
    mnShare.classList.remove('change');
    mnShareIcon.classList.remove('change');
    mnShareBtn.lastChild.data = mnShareText;

    if ( dialog.classList.contains('opened') ) {
      closeDialog();
      mnShareBtn.lastChild.data = mnShareText;
    }

  } else {
    // check to see if other buttons are open, and close them
    if ( mnSearchBtn.classList.contains('active') ) {
      mnSearchBtn.classList.remove('active');
      mnMagnifying.classList.remove('change');
      mnSearchBtn.lastChild.data = mnSearchText;
    } else if ( mnMenuBtn.classList.contains('active')) {
      mnMenuBtn.classList.remove('active');
      mnHamburger.classList.remove('change');
      mnMenuBtn.lastChild.data = mnMenuText;
    }

    // add class active to menu and replace innerHTML
    mnShareBtn.classList.add('active');
    mnShareIcon.classList.add('change');
    dialogContent.innerHTML = mnShare.innerHTML;
    mnShareBtn.lastChild.data = mnShareBtn.dataset.canrLanguageClose;

    // Do nothing if dialog is already open otherwise open dialog
    if (dialog.classList.contains('opened')) {} else {
      dialog.classList.add('dialog__mobile-nav');
      dialogContent.innerHTML = mnShare.innerHTML;
      openDialog();
    }
  }
}

const mobileMenuActions = () => {
  mnSearchBtn.addEventListener('click', toggleSearch);
  mnMenuBtn.addEventListener('click', toggleMenu);
  mnShareBtn.addEventListener('click', toggleShare);

  // If the menu is empty hide it and set the search button to 100% width
  if ( mnMenu.querySelector('ul').querySelectorAll('li').length == 0 ) {
    mnMenuBtn.style.visibility = "hidden";
    mnMenuBtn.style.width = "0";
    mnMenuBtn.setAttribute('aria-hidden', 'true');
    mnSearchBtn.style.width = "100%";
  }
};

// ---------------------------------------------------------
// Mobile Banner Height Adjustment
const bannerResize = () => {
  var headerHeight = document.getElementById("header").offsetHeight;
  var childBanner = document.querySelector(".template--child-photo");
  var homeBanner = document.querySelector(".template--home");
  var homeBannerText = document.querySelector(".template__photo__text");


  if ( childBanner != null ) {
    if ( window.innerWidth <= 1024 ) {
      childBanner.querySelector('.template__photo').style.height = headerHeight + 'px';
    } else {
      childBanner.querySelector('.template__photo').style.height = null;
    }
  }

  if ( homeBanner != null ) {
    if ( window.innerWidth <= 1024 ) {
      var totalHeight = headerHeight + homeBannerText.offsetHeight;
      homeBanner.querySelector('.template__photo').style.height = totalHeight + 'px';
    } else {
      homeBanner.querySelector('.template__photo').style.height = null;
    }
  }
};

//--------------------------------------------------
// Form Filter Code for setting scroll on filters on mobile
const setFilterFormHeight = () => {
  var filters = document.getElementsByClassName("mobile-filters")[0];
  var submitButtons = document.getElementById('searchClearSubmit');
  var searchWrapper = document.getElementById('searchWrapper');
  var filterFormHeight = searchWrapper.offsetHeight - (filters.offsetHeight + submitButtons.offsetHeight + 60);
  var filterFormWrapper = document.getElementById('filterWrapper');

  filterFormWrapper.style.height = filterFormHeight + "px";
}

//---------------------------------------------------
// Run this code if the screen is less than 1024px
if ( window.innerWidth <= 1024) {
  mobileMenuActions();
  bannerResize();
}

// If window width is resized to greater than 1024 close any dialog windows
// Also listen for screen resize and run the functions if screen goes from
// large to less
var resizeTimer;
window.addEventListener('resize', () => {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {
    bannerResize();
    if ( window.innerWidth <= 1024 ) {
      document.getElementById('js-searchButton').removeEventListener('click', toggleSearch);
      document.getElementById('js-menuButton').removeEventListener('click', toggleMenu);
      document.getElementById('js-shareButton').removeEventListener('click', toggleShare);
      mobileMenuActions();
    }  else if ( window.innerWidth > 1024 &&  dialog.classList.contains('opened') ) {
      closeDialog();
      mnMenuBtn.lastChild.data = mnMenuText;
      mnSearchBtn.lastChild.data = mnSearchText;
      mnShareBtn.lastChild.data = mnShareText;

    }
  }, 250);
})
